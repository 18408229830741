import React from 'react';
import { inject, observer } from 'mobx-react';
import { PayabliStorageManager } from '../api/localStorageManager';
import {
	BiCopy,
	BiDollar,
	BiEdit,
	BiMailSend,
	BiShow,
	BiShowAlt,
	BiUserCheck
} from 'react-icons/bi';
import { FileViewer } from './FileViewer';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import dayjs from 'dayjs';

@inject('global', 'reports', 'bill')
@observer
class QuickViewBill extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			vCardData: {
				cardNumber: null,
				cardExp: null,
				cardCvv: null,
				method: 'vcard'
			}
		};
	}

	componentWillUnmount() {
		this.resetVcardData();
	}

	resetVcardData() {
		const vcardData = {
			cardNumber: null,
			cardCvv: null,
			cardExp: null,
			method: 'vcard'
		};
		this.setState({ vCardData: vcardData });
	}

	getCardMethodByNumber(cardNumber) {
		if (cardNumber) {
			if (cardNumber.startsWith('4')) {
				return 'visa';
			} else if (cardNumber.startsWith('5')) {
				return 'mastercard';
			} else {
				return 'vcard';
			}
		} else {
			return 'vcard';
		}
	}

	formatCardNumber(cardNumber) {
		if (cardNumber) {
			return cardNumber.replace(/\d{4}(?=.)/g, '$& ');
		} else {
			return '•••• •••• •••• ••••';
		}
	}

	formatCardExp(cardExp) {
		return cardExp ? dayjs(cardExp).format('MM/YY') : '••/••';
	}

	getUnmaskedVcard(transId) {
		if (!transId) {
			return;
		}

		this.props.global.setLoading(true);
		this.props.bill
			.getUnmaskedVcard(transId)
			.then((response) => {
				this.props.global.setLoading(false);
				if (response) {
					const vCardData = {
						cardNumber: this.formatCardNumber(response.cardNumber),
						cardExp: this.formatCardExp(response.expirationDate),
						cardCvv: response.cvc,
						method: this.getCardMethodByNumber(response.cardNumber)
					};
					this.setState({ vCardData: vCardData });
				} else {
					const errorMessage = 'Something is Wrong!';
					toast.error(errorMessage, {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-error-container'
					});
				}
			})
			.catch((error) => {
				toast.error(error?.message || 'Something is Wrong!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				});
			});
	}

	copyToClipboard(e) {
		try {
			e.preventDefault();
			const vCardData = this.state.vCardData;
			const copyText = vCardData.cardNumber?.replace(/\s/g, '');
			if (!copyText) {
				toast.error('No data to copy!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'toast-error-container'
				});
				return;
			}
			navigator.clipboard.writeText(copyText);
			toast.success('Copied to clipboard!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-success-container'
			});
		} catch (e) {
			const errorMessage = 'Failed to copy!';
			toast.error(errorMessage, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'toast-error-container'
			});
		}
	}

	render() {
		let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
		let userPermissions =
			encryptStorage &&
			encryptStorage.getItem('pUser') &&
			encryptStorage.getItem('pUser').permissions
				? encryptStorage.getItem('pUser').permissions
				: [];
		const recordDetail = this.props.reports.getRecordDetails;
		return (
			<>
				<div className='small mb-5'>
					<div className='row'>
						<div className='col-6'>
							<label className='header'>Bill #</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.BillNumber ? recordDetail.BillNumber : '-'}
						</div>
					</div>
					<div className='row'>
						<div className='col-6'>
							<label className='header'>Status</label>
						</div>
						<div className='col-6'>{this.props.reports.getBillStatus(recordDetail?.Status)}</div>
					</div>

					<div className='row'>
						<div className='col-6'>
							<label className='header'>
								Date & Time {' (' + this.props.global.getTimeZone('v1') + ')'}
							</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.BillDate
								? this.props.global.stringDateFormat(recordDetail.BillDate)
								: '-'}
							-
							{recordDetail && recordDetail.BillDate
								? this.props.global.stringTimeFormat(recordDetail.BillDate)
								: '-'}
						</div>
					</div>
					<div className='row'>
						<div className='col-6'>
							<label className='header'>
								Due On {' (' + this.props.global.getTimeZone('v1') + ')'}
							</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.DueDate
								? this.props.global.stringDateFormat(recordDetail.DueDate)
								: '-'}
							-
							{recordDetail && recordDetail.DueDate
								? this.props.global.stringTimeFormat(recordDetail.DueDate)
								: '-'}
						</div>
					</div>

					<div className='row'>
						<div className='col-6'>
							<label className='header'>Payment Group</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Operation ? recordDetail.BatchNumber : '-'}
						</div>
					</div>
					<div className='row'>
						<div className='col-6'>
							<label className='header'>Type</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.PaymentMethod
								? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.PaymentMethod)
								: '-'}
						</div>
					</div>
					{recordDetail && recordDetail.Comments && (
						<div className='mb-3 mt-3 notes-horizontal-block'>
							<b>NOTES: </b> {recordDetail.Comments}
						</div>
					)}

					<div className='row mt-3 mb-2'>
						<div className='col-12'>
							<b>Amount</b>
						</div>
					</div>

					<div className='row small mb-2'>
						{recordDetail && recordDetail.BillItems && (
							<div className='col-12'>
								{recordDetail.BillItems.map((record, i) => (
									<div
										className='row mb-1'
										key={i}>
										<div className='col-6'>{record.itemProductName}</div>
										<div className='col-2 text-right'>
											${this.props.global.numberWithCommas(record.itemCost.toFixed(2))}
										</div>
										<div className='col-2 text-center'>x {record.itemQty ? record.itemQty : 1}</div>
										<div className='col-2 text-right'>
											$
											{this.props.global.numberWithCommas(
												((record.itemQty ? record.itemQty : 1) * record.itemCost).toFixed(2)
											)}
										</div>
									</div>
								))}
							</div>
						)}
					</div>

					<div className='row mb-4'>
						<div className='col-12'>
							<div className='row mb-1'>
								<div className='col-4'>
									<b>Subtotal</b>
								</div>
								<div className='col-8 text-right'>
									{recordDetail && recordDetail.NetAmount
										? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2))
										: '$0.00'}
								</div>
							</div>
							<div className='row mb-1'>
								<div className='col-4'>
									<b>Fee</b>
								</div>
								<div className='col-8 text-right'>
									{recordDetail && recordDetail.FeeAmount
										? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2))
										: '$0.00'}
								</div>
							</div>
							<div className='row mb-1'>
								<div className='col-4'>
									<b>Total</b>
								</div>
								<div className='col-8 text-right'>
									<b>
										{recordDetail && recordDetail.NetAmount
											? this.props.global.numberWithCommasNegative(
													recordDetail.NetAmount.toFixed(2)
											  )
											: '$0.00'}
									</b>
								</div>
							</div>
						</div>
					</div>
					{/*<h5 className="header mb-3">Payment Information</h5>
                
                <div className="card-v2 mb-3" style={{padding: "3em"}}>
                    




                </div>*/}

					<div className='text-center mb-4'>
						<div
							className='btn-group'
							role='group'>
							{userPermissions.indexOf('Bills') !== -1 && recordDetail && (
								<>
									{recordDetail &&
									recordDetail.Status !== -99 &&
									recordDetail.Status !== 2 &&
									recordDetail.Status !== 5 &&
									recordDetail.Status !== 50 &&
									recordDetail.Status !== 100 ? (
										<a
											className='btn btn-outline-primary text-transform-normal'
											href={
												'/' + this.props.global.getURLEntry() + '/bills/edit/' + recordDetail.IdBill
											}>
											<BiEdit /> Edit Bill
										</a>
									) : (
										<a
											className='btn btn-outline-primary text-transform-normal'
											href={
												'/' + this.props.global.getURLEntry() + '/bills/view/' + recordDetail.IdBill
											}>
											<BiShowAlt style={{ fontSize: '15px' }} /> View Bill
										</a>
									)}
								</>
							)}
							{userPermissions.indexOf('Bills') !== -1 && recordDetail?.Transaction?.CheckData && (
								<FileViewer
									fileData={recordDetail.Transaction.CheckData}
									fileType={recordDetail.Transaction.CheckData.ftype}
									isQuickView={true}
								/>
							)}
							{userPermissions.indexOf('Bills') !== -1 && (
								<>
									{recordDetail &&
										(recordDetail.Status === 1 || recordDetail.Status === 20) &&
										this.props.payBill && (
											<button
												onClick={() => this.props.payBill(recordDetail)}
												type='button'
												className='btn btn-outline-primary'>
												<BiDollar style={{ fontSize: '15px' }} /> Pay Bill
											</button>
										)}
								</>
							)}
							{userPermissions.indexOf('Bills') !== -1 && (
								<>
									{recordDetail && recordDetail.Status === 1 && this.props.sendForApproval && (
										<button
											onClick={() =>
												this.props.sendForApproval(
													recordDetail.IdBill,
													recordDetail.PaypointEntryname
												)
											}
											type='button'
											className='btn btn-outline-primary'>
											<BiMailSend /> Send for approval
										</button>
									)}
								</>
							)}
						</div>
					</div>

					{recordDetail && recordDetail.PaymentId && recordDetail.PaymentMethod === 'vcard' && (
						<>
							<h5 className='header mb-3'>Payment Information</h5>
							<div
								className='card-v2 mb-3'
								style={{ padding: '3em' }}>
								<div className='right-panel-card mb-4'>
									{this.props.global.getPaymethodImg(
										this.state.vCardData.method || 'vcard',
										'45px'
									)}
								</div>
								<div className='row mb-2'>
									<div className='col-8'>
										<label className='grey'>Card Number</label>
										<div
											className='d-flex flex-row text-nowrap '
											style={{ alignItems: 'center' }}>
											<h5
												className='mb-0'
												style={{ fontWeight: 500 }}>
												{this.state.vCardData.cardNumber
													? this.state.vCardData.cardNumber
													: '•••• •••• •••• ••••'}
											</h5>
											{recordDetail.Status === 50 && (
												<button
													className='btn btn-link py-0 ml-1 px-0'
													style={{ color: '#334155', boxShadow: 'none' }}
													onClick={
														this.state.vCardData.cardNumber
															? (e) => this.copyToClipboard(e)
															: () => this.getUnmaskedVcard(recordDetail.PaymentId)
													}>
													{this.state.vCardData.cardNumber ? (
														<BiCopy style={{ fontSize: '24px' }} />
													) : (
														<BiShow style={{ fontSize: '24px' }} />
													)}
												</button>
											)}
										</div>
									</div>
									<div className='col-4 text-center'>
										<label className='grey'>Expires on</label>
										<h5 style={{ fontWeight: 500 }}>
											{this.state.vCardData.cardExp ? this.state.vCardData.cardExp : '••/••'}
										</h5>
									</div>
								</div>
								<div className='row'>
									<div className='col-8'>
										<label className='grey'>Paypoint Legal Name</label>
										<h6 style={{ fontWeight: 500 }}>{`${
											recordDetail?.PaypointLegalname || ''
										}`}</h6>
									</div>
									<div className='col-4 text-center'>
										<label className='grey'>CVV</label>
										<h6 style={{ fontWeight: 500 }}>
											{this.state.vCardData.cardCvv ? this.state.vCardData.cardCvv : '•••'}
										</h6>
									</div>
								</div>
							</div>
						</>
					)}

					<h5 className='header mb-3'>
						Vendor Information &nbsp;
						{recordDetail && recordDetail.Vendor && (
							<a
								className='float-end no-underline fw-normal'
								style={{ fontSize: '12px' }}
								href={
									'/' +
									PayabliStorageManager.getEntryName() +
									'/vendor/' +
									recordDetail.Vendor.VendorId
								}>
								{' '}
								<BiUserCheck
									className='mr-1'
									style={{ fontSize: '16px' }}
								/>{' '}
								Vendor Details
							</a>
						)}
					</h5>
					<div className='row mb-4'>
						<div className='col-6'>
							<label className='header'>Name</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Vendor
								? (recordDetail.Vendor.Name1 ? recordDetail.Vendor.Name1 : '') +
								  ' ' +
								  (recordDetail.Vendor.Name2 ? recordDetail.Vendor.Name2 : '')
								: '-'}
						</div>
						<div className='col-6'>
							<label className='header'>Vendor #</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.VendorNumber
								? recordDetail.Vendor.VendorNumber
								: '-'}
						</div>
						<div className='col-6'>
							<label className='header'>Account #</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.customerVendorAccount
								? recordDetail.Vendor.customerVendorAccount
								: '-'}
						</div>
						<div className='col-6'>
							<label className='header'>Email</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.Email
								? recordDetail.Vendor.Email
								: '-'}
						</div>
						<div className='col-6'>
							<label className='header'>Phone #</label>
						</div>
						<div className='col-6'>
							{recordDetail && recordDetail.Vendor && recordDetail.Vendor.Phone
								? this.props.global.phoneNumberFormat(recordDetail.Vendor.Phone)
								: '-'}
						</div>
						<div className='col-6'>
							<label className='header'>Billing Address</label>
						</div>
						<div className='col-6'>
							{recordDetail?.Vendor?.Address1} {recordDetail?.Vendor?.Address2}{' '}
							{recordDetail?.Vendor?.City} {recordDetail?.Vendor?.State} {recordDetail?.Vendor?.Zip}
						</div>
						<div className='col-6 mt-2'>
							<label className='header'>Remit Address</label>
						</div>
						<div className='col-6 mt-2'>
							{recordDetail?.Vendor?.remitAddress1} {recordDetail?.Vendor?.remitAddress2}{' '}
							{recordDetail?.Vendor?.remitCity} {recordDetail?.Vendor?.remitState}{' '}
							{recordDetail?.Vendor?.remitZip}
						</div>
					</div>

					<h5 className='header mb-3'>Processing Information</h5>
					<div className='row mb-4'>
						<div className='col-3'>
							<label className='header'>Payment ID</label>
						</div>
						<div className='col-9'>{recordDetail?.PaymentId ?? '-'}</div>
						<div className='col-3'>
							<label className='header'>Source</label>
						</div>
						<div className='col-9 text-uppercase'>{recordDetail?.Source ?? '-'}</div>
					</div>
					{recordDetail && recordDetail.billEvents && recordDetail.billEvents.length > 0 && (
						<>
							<h5 className='header mb-3'>Bill History</h5>
							<div className='timeLineRecordContainer'>
								{recordDetail.billEvents.map((record, i) => (
									<div
										className='timeLineRecordItem'
										key={i}>
										<div className='timeLineRecord'>&nbsp;</div>
										<div className='timeLineRecordText'>
											<b>{record.description}</b>
											<br />
											{this.props.global.stringDateFormat(record.eventTime)}{' '}
											<span className='grey'>
												{this.props.global.stringTimeFormat(record.eventTime)}{' '}
												{'(' + this.props.global.getTimeZone('v1') + ')'}
											</span>
										</div>
										<br />
									</div>
								))}
							</div>
						</>
					)}
				</div>
				<ToastContainer transition={Bounce} />
			</>
		);
	}
}

export { QuickViewBill };
