import { action, computed, makeObservable, observable, toJS } from 'mobx';
import virtualTerminalStore from './VirtualTerminalStore';
import globalStore from './GlobalStore';
import invoiceStore from './InvoiceStore';
import axios from 'axios';
import { PayabliStorageManager } from '../api/localStorageManager';

class BillStore {
  globalStore = null;

  constructor(virtualTerminalStore, globalStore, invoiceStore) {
    this.virtualTerminalStore = virtualTerminalStore;
    this.globalStore = globalStore;
    this.invoiceStore = invoiceStore;
    makeObservable(this);
  }

  billId = null;

  @observable
  vendorSelected = {};
  
  @observable
  vendor = {};

  @observable
  items = [];
  
  @observable
  vendorOCR = null;
  
  @observable
  attachments = [];

  @observable
  pay = {
    subtotal: 0,
    fee: 0,
    total: 0,
  };

  @observable
  billDetails = {
    billNumber: null,
    dueDate: null,
    term: null,
    scheduleCheck: false,
    startDate: null,
    frequency: 'onetime',
    endDate: null,
    totalAmount: 0,
    JSON: null
  };

  @observable
  billActionSelected = {
    action: 'askVendor'
  };

  @observable
  status = 'new';

  @observable
  advancedOptions = {
  };

  @action
  setBillDetails(data) {
    this.billDetails = {
      ...this.billDetails,
      ...data,
    };
  }

  validateAch(paymentMethodData) {
    const validators = this.globalStore.validators;
    const { achAccountHolderName, achAccountType, achRouting, achAccount } = paymentMethodData;

    if (validators.isEmpty(achAccountHolderName) || validators.isMaxLength(250, achAccountHolderName)) return false;

    if (validators.isEmpty(achAccountType) || validators.isMaxLength(250, achAccountType)) return false;

    if (
      validators.isEmpty(achRouting) ||
      validators.isMaxLength(9, achRouting) ||
      validators.stringValidator('routing', achRouting)
    ) {
      return false;
    }

    if (
      validators.isEmpty(achAccount) ||
      validators.stringValidator('numbers', achAccount) ||
      validators.isMinLength(4, achAccount) ||
      validators.isMaxLength(17, achAccount)
    ) {
      return false;
    }

    return true;
  }

  getTermText(){
    let array = this.getOptionsDueDate();
    let thisObj = this;
    let returnText = "";
    array.forEach(function(item){
      if(item.value === thisObj.billDetails.term){
        returnText = item.text;
      }
    });
    return returnText;
  }

  getOptionsDueDate() {
    return [
      { value: 'DOR', label: 'Due on receipt', text: 'Due on receipt' },
      { value: 'NET10', label: '10 days after bill date', text: 'Net 10' },
      { value: 'NET15', label: '15 days after bill date', text: 'Net 15' },
      { value: 'NET20', label: '20 days after bill date', text: 'Net 20' },
      { value: 'NET30', label: '30 days after bill date', text: 'Net 30' },
      { value: 'NET45', label: '45 days after bill date', text: 'Net 45' },
      { value: 'NET60', label: '60 days after bill date', text: 'Net 60' },
      { value: 'NET90', label: '90 days after bill date', text: 'Net 90' },
      { value: 'EOM', label: 'Due end of this month', text: 'End of this month' },
      { value: '1MFI', label: '1st of the month following the bill date', text: '1st of the month' },
      { value: '5MFI', label: '5th of the month following the bill date', text: '5th of the month' },
      {
        value: '10MFI',
        label: '10th of the month following the bill date',
        text: '10th of the month',
      },
      {
        value: '15MFI',
        label: '15th of the month following the bill date',
        text: '15th of the month',
      },
      {
        value: '20MFI',
        label: '20th of the month following the bill date',
        text: '20th of the month',
      },
      { value: 'custom', label: 'Custom', text: 'Custom date' },
    ];
  }

  @action
  reset() {
    this.billId = null;
    this.status = 'new';
    this.vendorSelected = {};
    this.items = [];
    this.pay = {
      subtotal: 0,
      fee: 0,
      total: 0,
    };
    this.billActionSelected = {
      action: 'askVendor'
    };
    this.btnActionEnabled = false;
    this.billDetails = {
      billNumber: null,
      dueDate: null,
      term: null,
      scheduleCheck: false,
      startDate: null,
      frequency: 'onetime',
      endDate: null,
      totalAmount: 0
    };

    this.virtualTerminalStore.cleanCategories();
    this.virtualTerminalStore.clearCustomer();
    this.virtualTerminalStore.clearCustomerSelected();
  }

  @action
  setItems(items){
    this.items = items;
  }

  @action
  setAttachments(attachments){
    this.attachments = attachments;
  }

  @action updateStatusBill(status) {
    this.status = status;
  }

  @action
  updateBillActionSelected(action, data) {
    this.billActionSelected = {
      ...this.billActionSelected,
      action,
    };
  }

  @computed
  get getVendorSelected(){
      return this.vendorSelected;
  }

  @computed
  get isBtnActionEnabled() {
   
    // validate field minimum
    if (!this.vendorSelected.VendorId) return false;
    if (!this.billDetails.dueDate || !this.billDetails.billNumber) return false;
    //if (this.items.length === 0) return false;


    if ( !this.billDetails.totalAmount || parseFloat(this.billDetails.totalAmount) === 0) return false;
    
    // scheduled, has to have startDate
    if (this.billDetails.scheduleCheck && !this.billDetails.startDate) return false;

    // schedule, frequency different to One time has to have endData
    if (
      this.billDetails.scheduleCheck &&
      this.billDetails.frequency !== 'onetime' &&
      !this.billDetails.endDate
    )
      return false;

    // select how vendor gets paid
    if (this.billActionSelected.action === 'howVendorGetsPaid') {
      const paymentMethod = this.virtualTerminalStore.paymentMethod;
      const paymentMethodData = this.virtualTerminalStore.paymentPage.paymentMethods;
      if(paymentMethod && paymentMethod.toLowerCase() === 'ach'){
        return this.validateAch(paymentMethodData);
      }
    }

    return true;
  }

  collectData() {
    let vendorSelected = this.vendorSelected;
    let vendorSelectedObj = {
        vendorNumber: vendorSelected.VendorNumber,
        name1: vendorSelected.Name1,
        name2: vendorSelected.Name2,
        ein: vendorSelected.EIN,
        phone: vendorSelected.Phone,
        email: vendorSelected.Email,
        address1: vendorSelected.Address1,
        address2: vendorSelected.Address2,
        city: vendorSelected.City,
        state: vendorSelected.State,
        zip: vendorSelected.Zip,
        country: vendorSelected.Country,
        mcc: vendorSelected.Mcc,
        locationCode: vendorSelected.LocationCode,
        contacts: vendorSelected.Contacts ? [
          ...vendorSelected.Contacts.map((item) => {
            return {
              contactName: item.contactName,
              contactEmail: item.contactEmail,
              contactTitle: item.contactTitle,
              contactPhone: item.contactPhone
            };
          }),
        ]: null,
        billingData: vendorSelected.BillingData ? {
          bankName: vendorSelected.BillingData.bankName,
          routingAccount: vendorSelected.BillingData.routingAccount,
          accountNumber: vendorSelected.BillingData.accountNumber,
          typeAccount: vendorSelected.BillingData.typeAccount,
        } : null,
        paymentMethod: vendorSelected.PaymentMethod,
        vendorStatus: vendorSelected.VendorStatus,
        remitAddress1: vendorSelected.remitAddress1,
        remitAddress2: vendorSelected.remitAddress2,
        remitCity: vendorSelected.remitCity,
        remitState: vendorSelected.remitState,
        remitZip: vendorSelected.remitZip,
        remitCountry: vendorSelected.remitCountry,
        payeeName1: vendorSelected.payeeName1,
        payeeName2: vendorSelected.payeeName2,
        customerVendorAccount: vendorSelected.customerVendorAccount,
        internalReferenceId: vendorSelected.InternalReferenceId,
        additionalData: vendorSelected.additionalData ? {
          additionalProp1: vendorSelected.additionalData.additionalProp1,
          additionalProp2: vendorSelected.additionalData.additionalProp2,
          additionalProp3: vendorSelected.additionalData.additionalProp3
        } : null
    };

    const billStartDate = this.billDetails.startDate;
    const bill = {
      vendorSelected : vendorSelectedObj,
      billNumber : this.billDetails.billNumber,
      netAmount : this.billDetails.totalAmount,
      billDate : this.billDetails.startDate,
      dueDate : this.billDetails.dueDate,
      comments : this.advancedOptions.memoNote ? this.advancedOptions.memoNote : '',
      vendor : {
        VendorNumber: vendorSelected.VendorNumber
      },
      endDate: this.billDetails.endDate === 'untilcancelled' ? new Date(billStartDate.getFullYear() + 20, billStartDate.getMonth(), billStartDate.getDate()) : this.billDetails.endDate?.toISOString(),
      frequency : this.billDetails.frequency,
      mode: this.billDetails.scheduleCheck ? 1 : 0,
      billItems: [
        ...this.items.map((item) => {
          return {
            // itemProductCode: 'string',
            itemProductName: item.label,
            itemDescription: item.description,
            // itemCommodityCode: 'string',
            itemUnitOfMeasure: 'quantity',
            itemCost: item.value,
            itemQty: item.quantity,
            itemTotalAmount: item.value * item.quantity,
            itemTaxAmount: 0,
            itemTaxRate: 0,
          };
        }),
      ],
      attachments: this.attachments ? this.attachments : [],
      additionalData: this.billDetails.JSON ?  {JSON:this.billDetails.JSON} : null
    }
    return {bill};
  }

  collectPaylinkData(billInfo) {
    let categories = [
      {
        name : billInfo.BillNumber,
        type : "vendor",
        label : "",
        value : billInfo.NetAmount,
        description : "",
        order : 1,
        quantity : 1,
        showDescription : true,
        optionalPay : true
      }
    ];
    let invoiceList = []
    billInfo?.BillItems.map((item) => {
      invoiceList.push(
        {
          "name" : item["itemProductName"],
          "type" : "vendor",
          "label" : item["itemProductName"],
          "value" : item["itemTotalAmount"],
          "description" : item["itemProductName"],
          "order" : 1,
          "quantity" : item["itemQty"],
          "showDescription" : true,
          "optionalPay" : false
        }
      );      
    });
    const vendor = billInfo.Vendor;
    const { ftype, filename, furl, fContent } = this.invoiceStore.getPageLogo();
    return {
      "logo": {
        "enabled": true,
        "order": 0
      },
      "page": {
        "enabled": true,
        "order": 1,
        "header": "You are being Paid",
        "description": "For the amount of"
      },
      "amount": { 
        "categories": categories,
        "enabled": true,
        "order": 2,
      },
      "payor": {
        "enabled": false,
        "order": 0,
        "header": "string",
        "fields": [
          {
            "name": "string",
            "label": "string",
            "validation": "string",
            "value": "string",
            "required": true,
            "display": true,
            "fixed": true,
            "identifier": true,
            "order": 0,
            "width": 0,
            "fixedValidation": true,
            "onlyData": true
          }
        ]
      },
      "paymentMethods": {
        "enabled": true,
        "order": 3,
        "header": "How would you like to get paid?",
        "allMethodsChecked": true,
        "methods": {
          "visa": true,
          "mastercard": true,
          "discover": true,
          "amex": true,
          "eCheck": true,
          "applePay": true,
          "googlePay": true,
          "payPal": true,
          "bitPay": true
        }
      },
      "review": {
        "enabled": true,
        "order": 4,
        "header": ""
      },
      "messageBeforePaying": {
        "enabled": false,
        "order": 5,
        "label": "string"
      },
      "paymentButton": {
        "enabled": true,
        "order": 6,
        "label": "Get Paid"
      },
      "invoices": {
        "invoiceList": [
            {
              "invoiceId": billInfo.IdBill,
              "invoiceNumber": billInfo.BillNumber,
              "invoiceDate": billInfo.BillDate,
              "invoiceDueDate": billInfo.DueDate,
              "invoiceSentDate": null,
              "invoiceEndDate": billInfo.EndDate,
              "lastPaymentDate": null,
              "createdAt": billInfo.CreatedAt,
              "invoiceStatus": billInfo.Status,
              "invoiceType": 0,
              "frequency": billInfo.Frequency,
              "paymentTerms": billInfo.Terms,
              "termsConditions": null,
              "notes": billInfo.Comments,
              "tax": 0,
              "discount": 0,
              "invoiceAmount": billInfo.NetAmount,
              "invoicePaidAmount": 0,
              "purchaseOrder": null,
              "firstName": vendor.Name1,
              "lastName": vendor.Name2,
              "company": billInfo.PaypointLegalname,
              "shippingAddress1": vendor.Address1,
              "shippingAddress2": vendor.Address2,
              "shippingCity": vendor.City,
              "shippingState": vendor.State,
              "shippingZip": vendor.Zip,
              "shippingCountry": vendor.Country,
              "shippingEmail": vendor.Email,
              "shippingPhone": vendor.Phone,
              "summaryCommodityCode": vendor.Summary,
              "items": invoiceList,
              "customerId": vendor.VendorId
              }
          ]
        ,
        "enabled": false,
        "order": 0,
        "invoiceLink": {
          "label": "View invoice",
          "enabled": true,
          "order": 9
        },
        "viewInvoiceDetails": {
          "label": "View invoice details",
          "enabled": true,
          "order": 10
        }
      },
      "bills": {
        "enabled": false,
        "order": 0,
        "billLink": {
          "enabled": true,
          "order": 0,
          "label": "string"
        },
        "viewBillDetails": {
          "enabled": true,
          "order": 0,
          "label": "string"
        }
      },
      "notes": {},
      "contactUs": {
        "enabled": true,
        "order": 7,
        "header": "",
        "emailLabel": "email",
        "phoneLabel": "phone",
        "paymentIcons": true
      },
      
      "settings": {
        "color": "string",
        "customCssUrl": "string",
        "language": "string",
        "redirectAfterApproveUrl": "string",
        "redirectAfterApprove": true,
        "pageLogo": {
          "ftype": ftype || '',
          "filename": filename || '',
          "furl": furl?.toString() || '',
          "fContent": fContent || '',
        }
      }
    }
}

  @action
  async sendPaymentLink(billInfo, email) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const paylinkData = this.collectPaylinkData(billInfo);
    
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}PaymentLink/bill/${billInfo.IdBill}`,
        paylinkData,
        {
          headers: {
            requestToken: encryptStorage.getItem('pToken'),
          },
          params: {mail2: email}
        }
      )
      .then((res) => {
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async reSendPaymentLink(billInfo, email) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .get(
        `${process.env.REACT_APP_URL_API}PaymentLink/send/${billInfo.paylinkId}`,
        {
          headers: {
            requestToken: encryptStorage.getItem('pToken'),
          },
          params: {mail2: email}
        }
      )
      .then((res) => {
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async saveBill() {
    const { bill } = this.collectData();
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
  
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}Bill/single/${entryPoint}`,
        bill,
        {
          headers: {
            requestToken: encryptStorage.getItem('pToken'),
          },
        }
      )
      .then((res) => {
        //this.invoiceId = res.data.responseData;
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async processDocument(ftype, filename, fContent) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .post(
        `${process.env.REACT_APP_URL_API}Import/ocrDocumentJson/bill`,
        {
          ftype: ftype,
          filename: filename,
          fContent: fContent
        },
        {
          headers: {
            requestToken: encryptStorage.getItem('pToken'),
          },
        }
      )
      .then((res) => {
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  async schedulePayment(billToPay, paymentMethod) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    let entryPoint = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry;
    return axios.post(process.env.REACT_APP_URL_API+ 'MoneyOut/authorize?forceVendorCreation=false',
    {
        "entryPoint": entryPoint,
        "invoiceData": [
            {
              "billId": billToPay.IdBill
            }
        ],
        "vendorData": {
            "vendorId": billToPay.Vendor.VendorId
        },
        "paymentMethod": {
              "method": paymentMethod
        }
    },
    {
        headers: {'requestToken': encryptStorage.getItem('pToken')}
    })
    .then(res => {
       return res;
    })
    .catch(error => {
        throw error;
    });
  }

  @computed
  get hasVendorOCR(){
      if (this.vendorOCR) return true;
      return false;
  }

  @action
  setVendorOCR(vendorOCR){
    this.vendorOCR = vendorOCR;
  }

  async updateBill() {
    const { bill } = this.collectData();

    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    return axios
      .put(
        `${process.env.REACT_APP_URL_API}Bill/${this.billId}`,
        bill,
        {
          headers: {
            requestToken: encryptStorage.getItem('pToken'),
          },
        }
      )
      .then((res) => {
        return res.data.responseData;
      })
      .catch((error) => {
        throw error;
      });
  }

  @action
  async loadBill(billId) {
    let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

    const res = await axios.get(`${process.env.REACT_APP_URL_API}Bill/${billId}`, {
      headers: {
        requestToken: encryptStorage.getItem('pToken'),
      },
    }).catch(error => {
      throw error;
    });


    const { Vendor, BillNumber, Terms, NetAmount } = res.data.responseData;
    this.billId = billId;
    const vendorParsed = this.parseLoadVendor(Vendor);
    this.vendorSelected = vendorParsed;
    this.virtualTerminalStore.selectVendorObject(vendorParsed);

    this.billDetails.billNumber = BillNumber;
    this.billDetails.term = Terms;
    this.billDetails.totalAmount = NetAmount;

    return res.data.responseData;
  }

  @action
  getBillAttachments(billId, name){
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      return axios.get(process.env.REACT_APP_URL_API+ 'Bill/attachedFileFromBill/' + billId + "/" + name + "?returnObject=true",{
          headers: {
              'requestToken': encryptStorage.getItem('pToken'),
          }})
          .then(res => {
              return res.data;
          })
          .catch(error => {
              throw error;
          });
  }

  approveBill(billId, status){
      // status 1 : Approve
      // status 0 : Disapprove
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      return axios.get(process.env.REACT_APP_URL_API+ 'Bill/approval/' + billId + "/" + status,{
          headers: {
              'requestToken': encryptStorage.getItem('pToken'),
          }})
          .then(res => {
              return res.data;
          })
          .catch(error => {
              throw error;
          });
  }

  @action
  addItem(item) {
    this.items.push(item);
    this.calculatePay();
  }

  @action
  removeItem(position) {
    this.items.splice(position, 1);
    this.calculatePay();
  }

  @action
  updateQuantity(action, position) {
    if (action === 'add') {
      this.items[position].quantity = this.items[position].quantity + 1;
    }

    if (action === 'subtract' && this.items[position].quantity > 1) {
      this.items[position].quantity = this.items[position].quantity - 1;
    }

    this.calculatePay();
  }

  @action
  updateItem(item, position) {
    this.items[position] = {
      ...this.items[position],
      ...item,
    };
    this.calculatePay();
  }

  calculatePay() {
    const items = toJS(this.items);
    let subTotal = 0;
    items.forEach((item) => {
      subTotal += item.quantity * item.value;
    });

    this.pay = {
      ...this.pay,
      subtotal: subTotal,
      total: subTotal + this.pay.fee,
    };
  }

  @action
  updateAdvancedOptions(data) {
    this.advancedOptions = {
      ...this.advancedOptions,
      ...data,
    };
  }

  parseLoadVendor(pVendor) {

    let key, keys = Object.keys(pVendor);
    let n = keys.length;
    let vendor={}
    while (n--) {
      key = keys[n];
      vendor[key.toLowerCase()] = pVendor[key];
    }

    return {
      VendorNumber: vendor.vendornumber,
      Name1: vendor.name1,
      Name2: vendor.name2,
      Ein: vendor.ein,
      Phone: vendor.phone,
      Email: vendor.email,
      Address1: vendor.address1,
      Address2: vendor.address2,
      City: vendor.city,
      State: vendor.state,
      Zip: vendor.zip,
      Country: vendor.country,
      Mcc: vendor.mcc,
      LocationCode: vendor.locationcode,
      Contacts: vendor.contacts,
      BillingData: vendor.billingdata,
      PaymentMethod: vendor.paymentmethod,
      VendorStatus: vendor.vendorstatus,
      VendorId: vendor.vendorid,
      EnrollmentStatus: vendor.enrollmentstatus,
      PaypointLegalname: vendor.paypointlegalname,
      PaypointDbaname: vendor.paypointdbaname,
      PaypointEntryname: vendor.paypointentryname,
      ParentOrgName: vendor.parentorgname,
      CreatedDate: vendor.createddate,
      LastUpdated: vendor.lastupdated,
      RemitAddress1: vendor.remitaddress1,
      RemitAddress2: vendor.remitaddress2,
      RemitCity: vendor.remitcity,
      RemitState: vendor.remitstate,
      RemitZip: vendor.remitzip,
      RemitCountry: vendor.remitcountry,
      PayeeName1: vendor.payeename1,
      PayeeName2: vendor.payeename2,
      CustomerVendorAccount: vendor.customervendoraccount,
      InternalReferenceId: vendor.internalreferenceid,
      AdditionalData: vendor.additionaldata,
      Summary: vendor.summary
    }
  }

  getCalculateDueDateFromStartDateTerms() {
    let term = this.billDetails.term;
    let startDate = new Date(this.billDetails.startDate);
    if(term){
      switch (term) {
        case 'DOR':
          return startDate;
        case 'NET10':
          startDate.setDate(startDate.getDate() + 10);
          return startDate;
        case 'NET15':
          startDate.setDate(startDate.getDate() + 15);
          return startDate;
        case 'NET20':
          startDate.setDate(startDate.getDate() + 20);
          return startDate;
        case 'NET30':
          startDate.setDate(startDate.getDate() + 30);
          return startDate;
        case 'NET45':
          startDate.setDate(startDate.getDate() + 45);
          return startDate;
        case 'NET60':
          startDate.setDate(startDate.getDate() + 60);
          return startDate;
        case 'NET90':
          startDate.setDate(startDate.getDate() + 90);
          return startDate;
        case 'EOM':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        case '1MFI':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        case '5MFI':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 5);
        case '10MFI':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 10);
        case '15MFI':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 15);
        case '20MFI':
          return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 20);
        default:
          startDate.setDate(startDate.getDate() + 1);
          return startDate;
      }
    }else{
      return startDate;
    }
    
  }

  isUserAllowedToApprove(billApprovals){
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      let email = encryptStorage.getItem('pUser').email;
      let response = false;
      billApprovals.forEach(function(item){
        if(item.email === email){
          response = true;
        }
      });

      return response;
  }

  @action
  setVendorSelected(vendor) {
      this.vendorSelected = vendor;
  }

  getPaymentMethodForBill(value) {
    if(!value){
        return "";
    }
    switch (value) {
      case "0":
        return "";
      case "1":
        return "vcard";
      case "2":
        return "ach";
      case "3":
        return "check";
      case "4":
        return "managed";
      default:
        return ""
    }
  }

  async getUnmaskedVcard(transId){
    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_API}MoneyOut/vcard/unmask/by-trans-id/${transId}`, {
        headers: {
          'requestToken': encryptStorage.getItem('pToken'),
        }
      });
      if(res.status === 200){
        return res.data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  }

}

const billStore = new BillStore(virtualTerminalStore, globalStore, invoiceStore);
export default billStore;
