import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import CustomTable from '../CustomTable';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { RightPanel } from '../RightPanel';
import { QuickViewTransaction } from '../QuickViewTransaction';
import PartialBtnBadgeReport from './PartialBtnBadgeReport';

function PartialViewTable({ reports, global, entry, ctable }) {
	const quickViewTransaction = useRef();
	const { columns, visibleColumns, data, actions, elementSelected } = ctable;
	const { objectBatchRecord } = reports;
	const { Transfer = {}, IdBatch, PaypointId } = objectBatchRecord || {};
	const { BillingFeesAmount = 0, AddionalBillingAmount = 0 } = Transfer || {};

	useEffect(() => {
		const getDataFromAPI = async () => {
			const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
			let report = 'batchDetails',
				entryName = null,
				idElement = null,
				filters = null;

			entryName = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)?.pEntry;

			if (Transfer) {
				report = 'transferDetails';
				idElement = Transfer.TransferId;
			} else if (IdBatch) {
				filters = { 'batchId(eq)': IdBatch };
			}

			try {
				ctable.updateParameters({
					report: report,
					entry: entryName,
					orgId: idElement,
					filters: filters
				});
				ctable.resetFlagParamCategoryFilter();

				await ctable.getDataFromAPI();
			} catch (error) {
				throw error;
			}
		};

		if (objectBatchRecord) {
			getDataFromAPI();
		}
	}, [ctable, global, entry, objectBatchRecord, PaypointId, IdBatch, Transfer]);

	return (
		<>
			<RightPanel>
				<h5
					className='header mb-3'
					data-qaid='batchTransactionsDetailsMoneyIn'>
					Transaction Details
				</h5>
				<QuickViewTransaction
					ref={quickViewTransaction}
					recordDetail={ctable.recordDetail}
				/>
			</RightPanel>
			<div className='title-header-ctable'>
				{ctable.flagParamCategoryFilter === 'billingFees' && (
					<>
						<PartialBtnBadgeReport
							classNameProps={'title'}
							dataQaid={'transBillingBatchTransferMoneyIn'}
							isElementActive={true}
							isBadge={true}
							textTitle={
								<>
									{global.numberWithCommasNegative(BillingFeesAmount.toFixed(2))} Transaction
									Billing
								</>
							}
							isInfoIcon={true}
							infoTextTooltip={
								'Any billing item associated with a transaction, such as credit card authorization fee or ACH fee.'
							}
						/>
						<PartialBtnBadgeReport
							classNameProps={'title'}
							isBadge={true}
							textTitle={
								<>
									{global.numberWithCommasNegative(AddionalBillingAmount.toFixed(2))} Additional
									Billing
								</>
							}
							isInfoIcon={true}
							infoTextTooltip={
								'Any billing item not associated with transactions, such as monthly billing, or fees for value added services.'
							}
							isNotAllowed={true}
						/>
					</>
				)}
			</div>
			<CustomTable
				columns={columns}
				visibleColumns={visibleColumns}
				data={data}
				actions={actions}
				rowAction={(pos) => {
					ctable.openQuickView(pos);
				}}
				messageNotData={'No activity yet'}
				descriptionNotData={
					<span>{`${elementSelected || 'This'} activity will display when available.`}</span>
				}
			/>
		</>
	);
}

export default inject('global', 'reports', 'entry', 'ctable')(observer(PartialViewTable));
