import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GlobalStore from '../store/GlobalStore';
import { BiNotepad } from 'react-icons/bi';
import { CgUnavailable } from 'react-icons/cg';
import { MdOutlineCallSplit } from 'react-icons/md';

const instanceGlobal = GlobalStore;

function renderTooltipNotes(text) {
	return (
		<OverlayTrigger
			placement='top'
			overlay={<Tooltip>{text}</Tooltip>}>
			<BiNotepad className='label-info-icon in-input' />
		</OverlayTrigger>
	);
}

function getSettlementTransStatus(item) {
	if (!item) {
		return (
			<span className='badge bg-light'>
				<CgUnavailable /> N/A
			</span>
		);
	}
	const { SettlementStatus, method } = item;
	const params = { ...item, Method: method };
	return instanceGlobal.getSettlementTransStatus(SettlementStatus, params);
}

function parseSettlementData(item) {
	return {
		paymentId: item?.PaymentTransId || '-',
		transactionId: item?.GatewayTransId || '-',
		orderId: item?.OrderId || '-',
		internalPaypointId: '-',
		customer:
			item?.Customer?.FirstName || item?.Customer?.LastName
				? `${item?.Customer?.FirstName || ''} ${item?.Customer?.LastName || ''}`
				: '-',
		company: item?.Customer?.CompanyName || '-',
		email: item?.Customer?.BillingEmail || '-',
		phone: item?.Customer?.BillingPhone
			? instanceGlobal.phoneNumberFormat(item.Customer.BillingPhone)
			: '-',
		billingAddress:
			item?.Customer?.BillingAddress1 || item?.Customer?.BillingAddress2
				? `${item?.Customer?.BillingAddress1 || ''} ${item?.Customer?.BillingAddress2 || ''}`
				: '-',
		shippingAddress:
			item?.Customer?.ShippingAddress1 || item?.Customer?.ShippingAddress2
				? `${item?.Customer?.ShippingAddress1 || ''} ${item?.Customer?.ShippingAddress2 || ''}`
				: '-',
		invoice: item?.invoiceData?.invoiceNumber || '-',
		transactionDate: item?.TransactionTime
			? instanceGlobal.formatDateTimeWithStyle(item.TransactionTime)
			: '-',
		payMethod: item ? instanceGlobal.getPaymethodImgPaymentData(item) : '-',
		last4: item?.PaymentData?.MaskedAccount
			? instanceGlobal.maskedCardNumber(item.PaymentData.MaskedAccount, 'v5')
			: '-',
		type: item?.Operation || '-',
		cycle:
			item?.ScheduleReference || item.ScheduleReference === 0
				? item.ScheduleReference > 0
					? 'Scheduled'
					: 'One-Time'
				: '-',
		paymentStatus: instanceGlobal.getTransStatus(1),
		fundingStatus: (
			<>
				{instanceGlobal.getSettlementTransStatus(item.Status)}
				{Array.isArray(item.splitFundingInstructions) && (
					<OverlayTrigger
						placement='top'
						overlay={<Tooltip>Split Funded</Tooltip>}>
						<MdOutlineCallSplit
							style={{
								color: '#7B61FF',
								transform: 'rotate(90deg)',
								width: '16px',
								height: '16px'
							}}
						/>
					</OverlayTrigger>
				)}
			</>
		),
		transactionAmount: item?.NetAmount
			? instanceGlobal.numberWithCommasNegative(item.NetAmount.toFixed(2))
			: '$0.00',
		serviceGroup: '-',
		service: '-',
		billableEvent: '-',
		billableEventId: '-',
		frequency: '-',
		porcentPrice: '-',
		valuePrice: '-',
		billableAmount: '-',
		calculatedBillAmount: '-',
		description: '-'
	};
}

function parseTransferDetailsData(item) {
	return {
		paymentId: item?.transactionId || '-',
		transactionId: item?.transactionNumber || '-',
		orderId: item?.PaymentData?.paymentDetails?.orderId || '-',
		internalPaypointId: item?.paypointId || '-',
		customer:
			item?.Customer?.FirstName || item?.Customer?.LastName
				? `${item?.Customer?.FirstName || ''} ${item?.Customer?.LastName || ''}`
				: '-',
		company: item?.Customer?.CompanyName || '-',
		email: item?.Customer?.BillingEmail || '-',
		phone: item?.Customer?.BillingPhone
			? instanceGlobal.phoneNumberFormat(item.Customer.BillingPhone)
			: '-',
		billingAddress:
			item?.Customer?.BillingAddress1 || item?.Customer?.BillingAddress2
				? `${item?.Customer?.BillingAddress1 || ''} ${item?.Customer?.BillingAddress2 || ''}`
				: '-',
		shippingAddress:
			item?.Customer?.ShippingAddress1 || item?.Customer?.ShippingAddress2
				? `${item?.Customer?.ShippingAddress1 || ''} ${item?.Customer?.ShippingAddress2 || ''}`
				: '-',
		invoice: '-',
		transactionDate: item?.TransactionTime
			? instanceGlobal.formatDateTimeWithStyle(item.TransactionTime)
			: '-',
		payMethod: item ? instanceGlobal.getPaymethodImgPaymentData(item) : '-',
		last4: item?.PaymentData?.MaskedAccount
			? instanceGlobal.maskedCardNumber(item.PaymentData.MaskedAccount, 'v5')
			: '-',
		type: item?.category
			? `${item.category.charAt(0).toUpperCase()}${item.category.slice(1)}`
			: '-',
		cycle:
			item?.scheduleReference || item.scheduleReference === 0
				? item.scheduleReference > 0
					? 'Scheduled'
					: 'One-Time'
				: '-',
		paymentStatus: instanceGlobal.getTransStatus(item?.TransStatus),
		fundingStatus: (
			<>
				{getSettlementTransStatus(item)}
				{Array.isArray(item.splitFundingInstructions) && (
					<OverlayTrigger
						placement='top'
						overlay={<Tooltip>Split Funded</Tooltip>}>
						<MdOutlineCallSplit
							style={{
								color: '#7B61FF',
								transform: 'rotate(90deg)',
								width: '16px',
								height: '16px'
							}}
						/>
					</OverlayTrigger>
				)}
			</>
		),
		transactionAmount: item?.grossAmount
			? instanceGlobal.numberWithCommasNegative(item.grossAmount.toFixed(2))
			: '$0.00',
		serviceGroup: item?.billingFeesDetails
			? item.billingFeesDetails[0].serviceGroup
				? `${item.billingFeesDetails[0].serviceGroup
						.charAt(0)
						.toUpperCase()}${item.billingFeesDetails[0].serviceGroup.slice(1)}`
				: '-'
			: '-',
		service: item?.billingFeesDetails
			? item.billingFeesDetails[0].service
				? `${item.billingFeesDetails[0].service.toUpperCase()}`
				: '-'
			: '-',
		billableEvent: item?.billingFeesDetails
			? item.billingFeesDetails[0].billableEvent
				? `${item.billingFeesDetails[0].billableEvent.toUpperCase()}`
				: '-'
			: '-',
		billableEventId: item?.billingFeesDetails
			? item.billingFeesDetails[0].eventId
				? `${item.billingFeesDetails[0].eventId}`
				: '-'
			: '-',
		frequency: item?.billingFeesDetails
			? item.billingFeesDetails[0].frequency
				? `${item.billingFeesDetails[0].frequency
						.charAt(0)
						.toUpperCase()}${item.billingFeesDetails[0].frequency.slice(1)}`
				: '-'
			: '-',
		porcentPrice:
			item?.billingFeesDetails && item.billingFeesDetails[0].floatPrice
				? `${item.billingFeesDetails[0].floatPrice}%` || '-'
				: '-',
		valuePrice:
			item?.billingFeesDetails && item.billingFeesDetails[0].fixPrice
				? instanceGlobal.numberWithCommasNegative(item.billingFeesDetails[0].fixPrice.toFixed(2))
				: '$0.00',
		billableAmount:
			item?.billingFeesDetails && item.billingFeesDetails[0].billableAmount
				? instanceGlobal.numberWithCommasNegative(
						item.billingFeesDetails[0].billableAmount.toFixed(2)
				  )
				: '$0.00',
		calculatedBillAmount:
			item?.billingFeesDetails && item.billingFeesDetails[0].billAmount
				? instanceGlobal.numberWithCommasNegative(item.billingFeesDetails[0].billAmount.toFixed(2))
				: '$0.00',
		description:
			item?.billingFeesDetails && item?.billingFeesDetails[0].description
				? renderTooltipNotes(item.billingFeesDetails[0].description)
				: '-',
		transferAmount: item?.netTransferAmount
			? instanceGlobal.numberWithCommasNegative(item.netTransferAmount.toFixed(2))
			: '$0.00',
		splitFundingAmount: item?.splitFundingAmount
			? instanceGlobal.numberWithCommasNegative(item.splitFundingAmount.toFixed(2))
			: '$0.00'
	};
}

function parseReportData(type, data) {
	let objectData = [];
	switch (type) {
		case 'batchDetails':
			objectData = data.map(parseSettlementData);
			break;
		case 'transferDetails':
			objectData = data.map(parseTransferDetailsData);
			break;
		default:
			break;
	}

	return objectData;
}

export default parseReportData;
