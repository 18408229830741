import React from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { PayabliCookieManager, PayabliStorageManager } from '../../api/localStorageManager';
import { BiLowVision, BiShowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ReactCodeInput from "react-code-input";
import { Brand } from '../../components/Brand';
import Lottie from 'react-lottie';
import LoadingApplication from '../../assets/lotties/LoadingSquaredApplication.json';
import md5 from 'crypto-js/md5';
import * as Sentry from "@sentry/react";
import payabliLogo from "../../assets/images/payabli.png";

const propsReactCodeInput = {
  className: ReactCodeInput,
  inputStyle: {
    fontFamily: 'Poppins',
    margin:  '4px',
    MozAppearance: 'textfield',
    width: '45px',
    borderRadius: '5px',
    fontSize: '24px',
    height: '54px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    color: '#212529',
    border: '1px solid #ced4da',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px'
  },
  
}

@inject('global', 'user')
@observer
class Login extends React.Component {


    constructor(props) {
        super(props);
        this.myRefInput = React.createRef();
        this.state = {
            email: "",
            password: "",
            error: "",
            mfaError: "",
            showPassword: false,
            mfa: false,
            mfaCode: "",
            mfaMode: null,
            mfaValidationCode: "",
            showMfaCode: false,
            logo: "",
            resendCodeShowDelay: false,
            resendCodeTimeout: 0,

            pEntry: null, 
            legalName: null, 
            dbaName: null, 
            paypointId: null, 
            orgId: null, 
            timeZone: null
        };
        this.getCredentialsFromApi = this.getCredentialsFromApi.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextMFACode = this.handleTextMFACode.bind(this);
        this.validateEntryPoint = this.validateEntryPoint.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyDownCode = this.handleKeyDownCode.bind(this);
        this.setMfa = this.setMfa.bind(this);
        this.sendMFACode = this.sendMFACode.bind(this);
        this.reSendMFACode = this.reSendMFACode.bind(this);
        this.handleKeyDownEnter = this.handleKeyDownEnter.bind(this);
    }

    setMfa(value, e){
        if(e){
            e.preventDefault();
        }
        this.setState({mfa: value});
    }

    sendMFACode(){
        const val = (this.state.mfaMode === "authenticator" ? 6 : 5);
        if (this.state.mfaCode.length === val) {
            this.props.global.setLoading(true);
            return axios.post(process.env.REACT_APP_URL_API+ 'User/mfa', {
                "mfaCode": this.state.mfaCode,
                "mfaValidationCode": this.state.mfaValidationCode
            },
            {
                headers: {'requestToken': process.env.REACT_APP_TOKEN}
            })
            .then(res => {
                this.getUserAccess(res);
            })
            .catch(error => {
                this.setState({ mfaError : "Your multi-factor authentication code is not correct", mfaCode: ''});
                this.props.global.setLoading(false);
            });
        }
    }
    clearCode(e){
        this.setState({
            mfaCode: ""
        }, () => {
            let codeRef = this.myRefInput;
            codeRef.current.state.input[0] = "";
            codeRef.current.state.input[1] = "";
            codeRef.current.state.input[2] = "";
            codeRef.current.state.input[3] = "";
            codeRef.current.state.input[4] = "";
            if(codeRef.current.state.input.length > 5){
                codeRef.current.state.input[5] = "";
            }
            codeRef.current.textInput[0].focus();
        });
    }
    reSendMFACode(e){
        let timeout = parseInt(process.env.REACT_APP_MFA_TIMEOUT);
        let thisObj = this;
        if(e){
            e.preventDefault();
        }
        this.setState({
            resendCodeShowDelay: true,
            mfaError : "A new verification code has been sent to you.",
            mfaCode: ""
        });
        this.clearCode();

        let timer = setInterval(function(){
            thisObj.setState({
                resendCodeTimeout: timeout
            })
            timeout -= 1;

            if(timeout < 0){
                clearInterval(timer);
                thisObj.setState({
                    resendCodeShowDelay: false,
                    resendCodeTimeout: parseInt(process.env.REACT_APP_MFA_TIMEOUT)
                })
            }

        }, 1000);

        this.props.global.setLoading(true);
        return axios.post(process.env.REACT_APP_URL_API+ 'User/resendmfa/'+ this.state.email, {},
        {
            headers: {'requestToken': process.env.REACT_APP_TOKEN}
        })
        .then(res => {
            this.props.global.setLoading(false);
            if(res.data.mfaValidationCode)
            {
                this.setState({ mfaValidationCode: res.data.mfaValidationCode})
            }
        })
        .catch(error => {
            this.setState({ mfaError : "We have a problem sending your multi-factor authentication code."});
            this.props.global.setLoading(false);
        });
    }

    handleKeyDown(e){
        if (e.key === 'Enter') {
            this.getCredentialsFromApi();
          }
    }
    
    handleKeyDownCode(e){
        if (e.key === 'Enter') {
            this.sendMFACode();
          }
    }
    
    componentDidMount() {
        this.props.global.setLoading(true);
        let entry = this.props.match.params.entryUrl;
        this.validateEntryPoint(entry);

        this.setState({
            resendCodeTimeout: parseInt(process.env.REACT_APP_MFA_TIMEOUT)
        })

    }

    showPassword(){
        this.setState({ showPassword:!this.state.showPassword });
    }

    validateEntryPoint(entry){
        this.props.global.setLoading(true);
        
        if (typeof entry === 'object') {
            Sentry.captureException(new Error(`Entry point is not valid and is object: ${JSON.stringify(entry)}`));
            throw new Error('Entry point is not valid');
        }

        axios.get(process.env.REACT_APP_URL_API+ 'paypoint/basic/'+entry,
        {
            headers: {'requestToken':process.env.REACT_APP_TOKEN}
        })
        .then(res => {
            
            let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            
            if(encryptStorage && res?.data?.responseData?.Paypoint)
            {
                const entryImg = res.data.responseData.EntryLogo || '';
                PayabliCookieManager.createCookie(`${PayabliStorageManager.getEntryName()}_payabliEntryImgCookie_${process.env.REACT_APP_ENVIRONMENT}`, entryImg, 1);
                const entryImgState = entryImg || payabliLogo;

                this.setState({ 
                    logo: entryImgState,
                    pEntry: entry, 
                    legalName: res.data.responseData.Paypoint.LegalName,
                    dbaName: res.data.responseData.Paypoint.DbaName,
                    paypointId: res.data.responseData.Paypoint.IdPaypoint, 
                    orgId: res.data.responseData.Paypoint.ParentOrg.IdOrg, 
                    timeZone: res.data.responseData.Paypoint.TimeZone 
                });
            }
            
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.history.push('/');
            this.props.global.setLoading(false);
        });
    }

    getCredentialsFromApi(){
        this.setState({ error : ""});
        this.props.global.setLoading(true);
        return axios.post(process.env.REACT_APP_URL_API+ 'User/auth/ ', {
            "email": this.state.email,
            "psw": this.state.password
        },
        {
            headers: {'requestToken': process.env.REACT_APP_TOKEN}
        })
        .then(res => {
            if(res.data.mfa === true){
                this.props.global.setLoading(false);
                this.setState({mfa: true, mfaValidationCode: res.data.mfaValidationCode, mfaMode: res.data.mfaMode, mfaError: '', mfaCode: ''});
            }
            else if(res.data.responseData){
                this.getUserAccess(res);
            }else{
                console.log(res.data)
                this.setState({ error : "Something is Wrong"});
                this.props.global.setLoading(false);
            }
            
        })
        .catch(error => {
            console.error(error);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            this.setState({ error : errorMessage});
            this.props.global.setLoading(false);
        });
    }

    getUserAccess(res){
        let userToken = res.data.responseData;
        PayabliStorageManager.setEncryptedLocalStorageKey(userToken);
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();

        // To refresh token 10 minutes before token expire
        let remaining = (new Date().getTime()) + ((parseInt(res.data.remaining) - 10)*60000);

        axios.get(process.env.REACT_APP_URL_API+ 'User/0',
        {
            headers: {'requestToken':userToken}
        })
        .then(res => {
            if(res.data.userId){
                encryptStorage.setItem('pToken', userToken);
                this.props.user.getUserFromApi(res.data.userId, this.state.pEntry).then(pres => {
                    
                    if(pres && pres.Access){
                        let permissionsArray = [];
                        pres.Access.forEach(function (item) {
                            if(item.roleValue === true){
                                permissionsArray.push(item.roleLabel);
                            }
                        });

                        encryptStorage.setItem(`${PayabliStorageManager.getEntryName()}_pEntry`, 
                        {
                            pEntry: this.state.pEntry, 
                            legalName: this.state.legalName, 
                            dbaName: this.state.dbaName, 
                            paypointId: this.state.paypointId, 
                            orgId: this.state.orgId, 
                            timeZone: this.state.timeZone, 
                        }
                        );

                        encryptStorage.setItem('pUser', { 
                            name: res.data.Name,
                            id: res.data.userId, 
                            email: this.state.email, 
                            remaining: remaining,
                            permissions: permissionsArray,
                            atlasHmac: res.data?.AdditionalData?.atlasHmac,
                            timeZone: res.data.TimeZone ? res.data.TimeZone : 0
                        });
                        Sentry.setUser({ email: `${this.state.email}` });

                        const pToken = encryptStorage.getItem('pToken');
                        if(pToken){
                            try{
                                PayabliCookieManager.eraseCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`);
                                PayabliCookieManager.createCookie(`creatorToken_${process.env.REACT_APP_ENVIRONMENT}`, pToken, 1);
                            }catch(e){
                                Sentry.captureException(e);
                                console.log(e);
                            }
                        }
                       
                        window.location = `/${this.props.global.getURLEntry()}/dashboard`;
                    }
                })
                .catch(error => {
                    this.setState({ error : "Your entry point is not correct"});
                    this.props.global.setLoading(false);
                    throw error;
                });
                
                
                
            }else{
                this.setState({ error : "Something is Wrong"});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            this.setState({ error : errorMessage});
            this.props.global.setLoading(false);
        });
    }

    handleTextChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }
    
    handleTextMFACode(e) {
        this.setState({mfaCode : e}, () => {
            let qty = this.state.mfaMode === "authenticator" ? 6 : 5;
            if(this.state.mfaCode.length === qty){
                this.sendMFACode();
            }
            document.addEventListener('keydown', this.handleKeyDownEnter);
        });
    }

    handleKeyDownEnter(params){
        if (params.key === 'Enter') {
            document.removeEventListener('keydown', this.handleKeyDownEnter);
            this.sendMFACode();
        }
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingApplication,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          };
       return (
        <>
        {this.props.global.isLoading &&
                <div id="main-loading-layer" style={{backgroundColor: "rgba(255,255,255,1)"}}  className="d-flex justify-content-center align-items-center">
                   <Lottie
                        options={defaultOptions}
                        height={100}
                        width={100}
                    />
                </div>
                }
            <div className="root-page text-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "100%", position: "absolute", width: "100%"}}>
           
                {!this.state.mfa ? 
                <div className="card-login mb-3" style={{width: '35em'}}>
                    { this.state.logo && <Brand classBrand={'loginBrand'} imageBrand={this.state.logo} /> }
                    <p className="grey mb-4">Log Into Your PayHub</p>
                       <div className="form-floating mb-3">
                            <input autoCapitalize="off" onKeyDown={(e)=>this.handleKeyDown(e)} type="text" value={this.state.email} name="email" className={ this.state.error ? "form-control input-error": "form-control" } placeholder="Email"  onChange={(e) => this.handleTextChange(e)}/>
                            <label htmlFor="email">Email</label>
                        </div>

                        <div className="form-floating mb-2">
                            <input onKeyDown={(e)=>this.handleKeyDown(e)} type={ this.state.showPassword ? "text" : "password"} value={this.state.password}  name="password" className={ this.state.error ? "form-control input-error": "form-control" } placeholder="Password" onChange={(e) => this.handleTextChange(e)}/>  
                            <label htmlFor="password">Password</label>
                            {
                                this.state.showPassword ? 
                                <BiLowVision className="right-icon-on-input" onClick={(e)=>this.showPassword()}/> 
                                :
                                <BiShowAlt className="right-icon-on-input" onClick={(e)=>this.showPassword()}/>
                            }
                            
                        </div>

                        {
                            this.state.error &&
                            (
                                <div className="text-danger mb-3 small">{this.state.error}</div>
                            )
                        }

                        <div className="row  mb-4">
                           

                            <div className="col-12">
                           
                            <Link className="small" to={"/"+this.state.pEntry+"/forgotpassword"}>Reset Password</Link>

                            </div>

                        </div>
                       
                        <button className="full-w btn btn-lg btn-success mb-4" onClick={(e) => this.getCredentialsFromApi()}>Login</button>
              
                </div>
                :
                <div className="card-login mb-3" style={{width: '35em'}}>
                    
                    { this.state.logo && <Brand classBrand={'loginBrand'} imageBrand={this.state.logo} /> }
                        <p className="grey mb-4">
                            {this.state.mfaMode === "authenticator" &&
                            <>Enter Google Authentication code for your multi-factor authentication.</>
                            }
                            {this.state.mfaMode === "email" &&
                            <>You will receive code by email notification. Enter code for your multi-factor authentication.</>
                            }
                            {this.state.mfaMode === "sms" &&
                            <>You will receive code by text notification. Enter code for your multi-factor authentication.</>
                            }
                        </p>
                        <div className="mb-3">
                            <ReactCodeInput ref={this.myRefInput} onChange={(e)=> this.handleTextMFACode(e)} name="mfaCode" inputMode="tel" type="number" fields={this.state.mfaMode === "authenticator" ? 6 : 5} {...propsReactCodeInput}/>
                        </div>   

                        {
                            this.state.mfaError &&
                            (
                                <div className="text-danger mb-4 small">{this.state.mfaError}</div>
                            )
                        }
                       
                        <button className="full-w btn btn-lg btn-success mb-4" onClick={(e) => this.sendMFACode()}>Verify Code</button>

                        {
                            this.state.mfaMode !== "authenticator" &&
                            <span className={ this.state.resendCodeShowDelay ? "grey small cursor-pointer" : "small cursor-pointer blue-text" } href="/" onClick={(e)=> this.state.resendCodeShowDelay === false ? this.reSendMFACode(e) : ''}>Resend verification code {this.state.resendCodeShowDelay && <>({this.state.resendCodeTimeout})</> }</span>
                        }<br/><br/>
                        <a className="small mt-3 no-underline" href="/" onClick={(e)=> this.setMfa(false, e)}>Go back to login</a>
              
                </div>
                }
                
                <p className="small-small">Powered by  <a href="/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
        </>
        )
    }
}

export { Login };