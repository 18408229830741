import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { InfoConsentCustomer } from '../../../components/InfoConsentCustomer';
import { withRouter } from 'react-router-dom';
import { PayabliStorageManager } from '../../../api/localStorageManager';

@inject('store', 'global', 'invoice', 'vTerminal')
@observer
class ModalConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: '',
      inputRadio: null,
      emailsError : false
    };

    this.handleEmails = this.handleEmails.bind(this);
    this.handleProcessInvoice = this.handleProcessInvoice.bind(this);
    this.isValidAdditionalEmails = this.isValidAdditionalEmails.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps){
    if(this.props.open === true && prevProps.open === false){
        this.setState({
          emails: '',
          emailsError : false
        })
    }
  }

  handleEmails(e) {
    this.setState({ emails: e.target.value, emailsError : false });
  }

  handleRadioChange(e) {
    this.setState({ inputRadio: e.target.value });
  }

  redirectToInvoiceReport() {
    // clean stores
    this.props.vTerminal.cleanCategories();
    this.props.invoice.reset();

    this.props.history.push({
      pathname: "/"+PayabliStorageManager.getEntryName()+"/report/invoices"
    });
  }

  isValidAdditionalEmails(){
    return this.props.global.validators.stringValidator('emailSemicolon',this.state.emails);
  }

  async chargeCustomer() {
    try {
      this.props.vTerminal.handlePayorTextChange('invoiceNumber', this.props.invoice.invoiceDetails.invoiceNumber);
      const response = await this.props.vTerminal.makePayment();
      if (response?.data?.responseData?.resultCode !== 1) {
        let message = '';
        if (response?.data?.responseText) {
          message = 'Sorry, the payment was ' + response.data.responseText;
        } else if (response?.data?.responseData?.resultText){
          message = response.data.responseData.resultText;
        }else{
          message = 'Sorry, we have a problem sending your payment.'
        }
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
        return false;
      } else {
        if (response?.data?.responseData?.referenceId) {
          this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
        }
      }
      return true;
    } catch (reason) {
      let message = '';
      if (reason.data) {
        message = reason.data.responseText
        ? reason.data.responseText
        : 'Sorry, we have a problem sending your payment.';
      } else if (reason.request?.response) {
        if (typeof reason.request.response === 'string') {
          let messageJson = JSON.parse(reason.request.response);
          if (messageJson.responseText) {
            message = messageJson.responseText;
          } else {
            message = reason.request.response;
          }
        } else {
          message = JSON.parse(reason.request.response).responseText;
        }
      }
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
      return false;
    }
  }

  async handleProcessInvoice() {
    this.props.global.setLoading(true);
    let customerSelected = this.props.invoice.customerSelected;

    if(((!customerSelected.Email || customerSelected.Email==='') && (!this.state.emails || this.state.emails === '')) || 
       ((!customerSelected.Email || customerSelected.Email==='') && (this.state.emails || this.state.emails !== '') && (!this.isValidAdditionalEmails()))
    ){
      this.props.global.setLoading(false);
      this.setState({emailsError : true})
      return;
    }
    else{
      this.setState({emailsError : false})
    }

    
    if((this.state.emails || this.state.emails !== '') && (!this.isValidAdditionalEmails())){
      this.props.global.setLoading(false);
      this.setState({emailsError : true})
      return;
    }

    try {
      const { action } = this.props.invoice.invoiceActionSelected;

      if (action === 'chargeCustomer') {
        /* save invoice */
        if (this.props.invoice.status === 'new') {
          await this.props.invoice.saveInvoice(1);
        } else {
          await this.props.invoice.updateInvoice(1);
        }
        /* do charge and send Email */
        const success = await this.chargeCustomer();
        if (success) {
          await this.props.invoice.sendInvoiceWithoutStatus(this.state.emails);
          this.props.global.setLoading(false);
          this.redirectToInvoiceReport();
          return toast.success('Your invoice was charged and sent successfully.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-success-container',
          });
        }
      }

      const type = this.state.inputRadio === '1' ? 'sms' : 'email';
      const additionalEmails = this.state.emails !== '' ? this.state.emails?.split(';') : [];
      if (action === 'sendInvoice') {
        const response = await this.props.invoice.sendInvoiceWithPaylinkV2(type, additionalEmails);
        const { data, status } = response || {};
        const { isSuccess, responseData, responseCode } = data || {};
        this.redirectToInvoiceReport();
        
        if (!isSuccess) {
          toast.error(responseData?.explanation || 'Your invoice was not sent.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          });

          setTimeout(() => {
            if (status === 403 && responseCode === 6002) {
              toast.success('The request for consent was sent to the client.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container',
              });
            }
          }, 2000);

          return;
        }

        return toast.success('Your invoice was saved and sent successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
      }
    } catch (e) {
      this.props.global.setLoading(false);

      if (e.response) {
        return toast.error(e.response.data.responseText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      }

      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-error-container',
      });
    }

    this.props.global.setLoading(false);
  }

  render() {
    const action = this.props.invoice.invoiceActionSelected.action;
    const subtotal = this.props.invoice.pay.subtotal;
    const totalToPay = `$${this.props.global.numberWithCommas(parseFloat(subtotal).toFixed(2))}`;
    let btnText = null;
    let modalTitle = null;

    const { customerSelected } = this.props.invoice;
    const { customerConsent } = this.props.vTerminal.customerSelected;
    const { eCommunication, sms } = customerConsent || {};

    switch (action) {
      case 'sendInvoice':
        modalTitle = `Send invoice with personalized link`;
        btnText = 'Send invoice';
        break;
      case 'chargeCustomer':
        modalTitle = `Please review`;
        btnText = `Charge customer ${totalToPay}`;
        break;
      default:
        break;
    }

    return (
      <Modal show={this.props.open} onHide={this.props.close} size="md" centered>
        <Modal.Body className="popover-body" id="modal-confirmation">
          <div className="row mb-4 underlined">
            <div className="col">
              <h4>{modalTitle}</h4>
            </div>
          </div>

          <p className="description">
            Invoice <b>{customerSelected.Company ? customerSelected.Company+ " - ": ""}{customerSelected.Firstname} {customerSelected.Lastname}</b> for <b>{totalToPay}</b>
          </p>

          <div className="icheck-primary mr-3 mb-2">
            <input type="radio" name="inlineRadioEmail" id="inlineRadioEmail" value='0' checked={this.state.inputRadio === '0'} onChange={(e) => this.handleRadioChange(e)} />
            <label htmlFor="inlineRadioEmail" style={{ fontSize: '14px' }}>
              <p className="d-flex flex-row" style={{ alignItems: 'center' }}>
                Email on file - { (!customerSelected.Email || customerSelected.Email==='') ? this.state.emails : customerSelected.Email} 
                <InfoConsentCustomer type={0} data={eCommunication} />
              </p>
            </label>
          </div>
          
          { this.state.inputRadio === '0' ?
              <div className="ml-3" style={{ marginTop: '-10px' }}>
                <input
                  id="invoice-emails"
                  value={this.state.emails}
                  onChange={(e) => this.handleEmails(e)}
                  type="text"
                  className= {this.state.emailsError === true ? "input-error form-control mb-3" : "form-control mb-3"}
                  placeholder="Add emails (optional) / separate by semicolon"
                />
              </div>
            : null
          }

          { customerSelected?.Phone ?
            <div className="icheck-primary mr-3">
              <input type="radio" name="inlineRadioPhone" id="inlineRadioPhone" value='1' checked={this.state.inputRadio === '1'} onChange={(e) => this.handleRadioChange(e)} />
              <label htmlFor="inlineRadioPhone" style={{ fontSize: '14px' }}>
                <p className="d-flex flex-row" style={{ alignItems: 'center' }}>
                  Phone on file - { this.props.global.phoneNumberFormat(customerSelected.Phone) }
                  <InfoConsentCustomer type={1} data={sms} />
                </p>
              </label>
            </div>
            : null
          }

          <div className="row mt-4">
            <div id="modal-confirmation-buttons" className="col">
              <button className="btn btn-light" type="button" onClick={this.props.close}>
                Back to editing
              </button>
              <button
                className="btn btn-main btn-default btn-bordered-success"
                type="button"
                disabled={!this.state.inputRadio || this.state.emailsError}
                onClick={this.handleProcessInvoice}
              >
                {btnText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ModalConfirmation);
