import { makeObservable, action } from "mobx";
import { PayabliStorageManager } from '../api/localStorageManager'
import axios from 'axios';

class ManagementStore {

    constructor() {
        makeObservable(this);
    }

    @action
    apiRequest = async (endpoint, data) => {
        try {
            const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
            const response = await axios.post(process.env.REACT_APP_URL_API + endpoint, data, {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    @action
    async getAvailableBalanceMerchant(entry) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        try {
            const dt = await axios.get(process.env.REACT_APP_URL_API + `Management/getAvailableBalanceMerchant?entry=${entry}&sync=true`, {
                headers: {
                    'requestToken': encryptStorage.getItem('pToken'),
                }
            });
            return dt?.data?.balanceData;
        } catch (error) {
            throw error;
        }
    }

    @action
    async actionFraudControl(type, fraudHistoryId) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
            id = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId,
            data = {
                "type": type,
                "fraudHistoryId": fraudHistoryId
            };
        return axios.post(process.env.REACT_APP_URL_API + 'Management/fraudblacklist/0/' + id, data, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    @action
    async actionRemoveFraudControl(fraudHistoryId) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage(),
            id = encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).orgId
        return axios.delete(process.env.REACT_APP_URL_API + 'Management/fraudblacklist/0/' + id + '/' + fraudHistoryId, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken')
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    async actionBlockPaypointFraudControl(idPaypoint) {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage()
        return axios.put(process.env.REACT_APP_URL_API + 'Management/paypointStatus/' + idPaypoint, { "paypointStatus": 0 }, {
            headers: {
                'requestToken': encryptStorage.getItem('pToken'),
            }
        })
            .then(res => {
                return res;
            })
            .catch(error => {
                throw error;
            });
    }

    actionHoldTransaction = async (payabliTransId) => {
        const data = {
            "payabliTransId": payabliTransId
        };
        return this.apiRequest('Management/holdTransaction', data);
    };

    actionReleaseTransaction = async (payabliTransId) => {
        const data = {
            "payabliTransId": payabliTransId
        };
        return this.apiRequest('Management/releaseTransaction', data);
    };

    actionHoldBatch = async (idBatch) => {
        const data = {
            "idBatch": idBatch
        };
        return this.apiRequest('Management/holdBatch', data);
    };

    actionReleaseBatch = async (idBatch) => {
        const data = {
            "idBatch": idBatch
        };
        return this.apiRequest('Management/releaseBatch', data);
    };
}

const managementStore = new ManagementStore();
export default managementStore;